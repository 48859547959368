import type { IChain } from "@sablier/v2-types";

const CHAIN_ETHEREUM_ID = 1;
const CHAIN_GOERLI_ID = 5;
const CHAIN_POLYGON_ID = 137;
const CHAIN_ARBITRUM_ID = 42161;
const CHAIN_AVALANCHE_ID = 43114;
const CHAIN_BASE_ID = 8453;
const CHAIN_BSC_ID = 56;
const CHAIN_GNOSIS_ID = 100;
const CHAIN_OPTIMISM_ID = 10;
const CHAIN_SCROLL_ID = 534352;

const _chains: { [key: number]: IChain } = {
  [CHAIN_ARBITRUM_ID]: {
    chainId: CHAIN_ARBITRUM_ID,
    name: "Arbitrum",
    explorer: "https://arbiscan.io",
    rpcPrivate: (key) => `https://arbitrum-mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://arb1.arbitrum.io/rpc",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-arbitrum",
    subgraphFallback: () => "",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"],
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_AVALANCHE_ID]: {
    chainId: CHAIN_AVALANCHE_ID,
    name: "Avalanche",
    explorer: "https://snowtrace.io",
    rpcPrivate: (key) => `https://avalanche-mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://avalanche.public-rpc.com",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-avalanche",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-avalanche",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7"],
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
  },
  [CHAIN_BSC_ID]: {
    chainId: CHAIN_BSC_ID,
    name: "Binance Smart Chain",
    explorer: "https://bscscan.com",
    rpcPrivate: (key) => `https://bnbsmartchain-mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://bsc.publicnode.com",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-bsc",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-bsc",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"],
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
  },
  [CHAIN_BASE_ID]: {
    chainId: CHAIN_BASE_ID,
    name: "Base",
    explorer: "https://basescan.org",
    rpcPrivate: (_key) => `https://mainnet.base.org`,
    rpcPublic: () => "https://mainnet.base.org",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-base",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-base",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0x4200000000000000000000000000000000000006"],
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_OPTIMISM_ID]: {
    chainId: CHAIN_OPTIMISM_ID,
    name: "Optimism",
    explorer: "https://optimistic.etherscan.io",
    rpcPrivate: (key) => `https://optimism-mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://mainnet.optimism.io",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-optimism",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-optimism",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0x4200000000000000000000000000000000000006"],
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_GNOSIS_ID]: {
    chainId: CHAIN_GNOSIS_ID,
    name: "Gnosis",
    explorer: "https://gnosisscan.io",
    rpcPrivate: (key) => `https://bnbsmartchain-mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://gnosis-mainnet.public.blastapi.io",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-gnosis",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-gnosis",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d"],
      name: "xDAI",
      symbol: "xDAI",
      decimals: 18,
    },
  },
  [CHAIN_ETHEREUM_ID]: {
    chainId: CHAIN_ETHEREUM_ID,
    name: "Ethereum",
    explorer: "https://etherscan.io",
    rpcPrivate: (key) => `https://mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://api.mycryptoapi.com/eth",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"],
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_GOERLI_ID]: {
    chainId: CHAIN_GOERLI_ID,
    name: "Goerli",
    explorer: "https://goerli.etherscan.io",
    isTestnet: true,
    rpcPrivate: (key) => `https://goerli.infura.io/v3/${key}`,
    rpcPublic: () => "https://rpc.goerli.mudit.blog/",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-goerli",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-goerli",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: [],
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [CHAIN_POLYGON_ID]: {
    chainId: CHAIN_POLYGON_ID,
    name: "Polygon",
    explorer: "https://polygonscan.com",
    isTestnet: false,
    rpcPrivate: (key) => `https://polygon-mainnet.infura.io/v3/${key}`,
    rpcPublic: () => "https://polygon-rpc.com/",
    subgraph: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-polygon",
    subgraphFallback: () =>
      "https://api.thegraph.com/subgraphs/name/sablier-labs/sablier-v2-polygon",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"],
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
  },
  [CHAIN_SCROLL_ID]: {
    chainId: CHAIN_SCROLL_ID,
    name: "Scroll",
    explorer: "https://scrollscan.com/",
    rpcPrivate: (_key) => `https://rpc.scroll.io`,
    rpcPublic: () => "https://rpc.scroll.io",
    subgraph: () =>
      "https://api.studio.thegraph.com/query/30310/sablier-v2-scroll/version/latest",
    subgraphFallback: () =>
      "https://api.studio.thegraph.com/query/30310/sablier-v2-scroll/version/latest",
    token: {
      utility: ["0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"],
      wrapped: ["0x5300000000000000000000000000000000000004"],
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
  },
};

const chains: {
  [
    key:
      | string
      | "arbitrum"
      | "avalanche"
      | "base"
      | "bsc"
      | "goerli"
      | "gnosis"
      | "mainnet"
      | "optimism"
      | "polygon"
      | "scroll"
  ]: IChain;
} = {
  ..._chains,
  arbitrum: _chains[CHAIN_ARBITRUM_ID],
  avalanche: _chains[CHAIN_AVALANCHE_ID],
  base: _chains[CHAIN_BASE_ID],
  bsc: _chains[CHAIN_BSC_ID],
  optimism: _chains[CHAIN_OPTIMISM_ID],
  gnosis: _chains[CHAIN_GNOSIS_ID],
  goerli: _chains[CHAIN_GOERLI_ID],
  mainnet: _chains[CHAIN_ETHEREUM_ID],
  polygon: _chains[CHAIN_POLYGON_ID],
  scroll: _chains[CHAIN_SCROLL_ID],
};

export default chains;
