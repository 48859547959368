import { _ } from "@sablier/v2-mixins";
import sizes from "./sizes";

type Size = number | string;

const unit = (size: Size) =>
  !_.isNil(size) && !_.toString(size).endsWith("px") ? `${size}px` : size;

const medias = {
  max: (max: Size) => `@media all and (max-width: ${unit(max)})`,
  min: (min: Size) => `@media all and (min-width: ${unit(min)})`,
  between: (min: Size, max: Size) =>
    `@media all and (min-width: ${unit(min)}) and (max-width: ${unit(max)})`,

  get betweenXSAndSM() {
    return this.between(sizes.deviceXS, sizes.deviceSM);
  },

  get betweenSMAndMD() {
    return this.between(sizes.deviceSM, sizes.deviceMD);
  },

  get betweenMDAndLG() {
    return this.between(sizes.deviceMD, sizes.deviceLG);
  },
  get betweenLGAndXL() {
    return this.between(sizes.deviceLG, sizes.deviceXL);
  },
  get betweenXLAndContainer() {
    return this.between(sizes.deviceXL, sizes.deviceContainer);
  },
  get maxXXS() {
    return this.max(sizes.deviceXXS);
  },
  get maxXS() {
    return this.max(sizes.deviceXS);
  },
  get maxSM() {
    return this.max(sizes.deviceSM);
  },
  get maxMD() {
    return this.max(sizes.deviceMD);
  },
  get maxLG() {
    return this.max(sizes.deviceLG);
  },
  get maxXL() {
    return this.max(sizes.deviceXL);
  },
  get maxContainer() {
    return this.max(sizes.deviceContainer);
  },
  get minXXS() {
    return this.min(sizes.deviceXXS);
  },
  get minXS() {
    return this.min(sizes.deviceXS);
  },
  get minSM() {
    return this.min(sizes.deviceSM);
  },
  get minMD() {
    return this.min(sizes.deviceMD);
  },
  get minLG() {
    return this.min(sizes.deviceLG);
  },
  get minXL() {
    return this.min(sizes.deviceXL);
  },
  get minContainer() {
    return this.min(sizes.deviceContainer);
  },
};

export default medias;
