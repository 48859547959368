import { css, keyframes } from "styled-components";

const fadeInKeyframes = keyframes`
    0%{
        opacity: 0
    }
    100%{
      opacity: 1;
    }
`;

const fadeInKeyframesTo = (to: string) => keyframes`
    0%{
        opacity: 0
    }
    100%{
      opacity: ${to}
    }
`;

const fadeIn = css`
  opacity: 0;
  animation-name: ${fadeInKeyframes};
  animation-duration: 250ms;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const fadeOutKeyframes = keyframes`
    0%{
        opacity: 1
    }
    100%{
      opacity: 0;
    }
`;

const fadeOutKeyframesTo = (to: string) => keyframes`
    0%{
        opacity: 1
    }
    100%{
      opacity: ${to}
    }
`;

const fadeOut = css`
  opacity: 0;
  animation-name: ${fadeOutKeyframes};
  animation-duration: 250ms;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const progressKeyframes = keyframes`
 0% {
    stroke-dasharray: 0 var(--end, 100);
  }
  100% {
    stroke-dasharray: var(--progress-to, var(--end, 100));
  }
`;

const progress = css`
  animation-name: ${progressKeyframes};
  animation-duration: 3000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
`;

const progressFadeKeyframes = keyframes`
  0% {
    opacity: 1;
    stroke-dasharray: 0 var(--end, 100);
  }
  55% {
    opacity: 1;
    stroke-dasharray: var(--end, 100) var(--end, 100);
  }
  65% {
    opacity: 0;
    stroke-dasharray: var(--end, 100) var(--end, 100);
  }
  100% {
    opacity: 0;
    stroke-dasharray: var(--end, 100) var(--end, 100);
  }
`;

const progressFade = css`
  animation-name: ${progressFadeKeyframes};
  animation-duration: 7000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`;

const deckKeyframes = keyframes`
  0%{
    opacity: 0%;
    filter: brightness(2);
    transform: translateY(-6px)
  }

  50%{
    opacity: 100%;
    transform: translateY(0);
  }

  100%{
    opacity: 100%;
    filter: brightness(1);
    transform: translateY(0)
  }
`;

const deck = css`
  animation-name: ${deckKeyframes};
  animation-duration: 800ms;
  animation-timing-function: ease-in-out;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const slideUpKeyframes = keyframes`
    0%{
        transform: translate(100px);
    }
    100%{
      transform: translate(0);
    }
`;

const slideUp = css`
  animation-name: ${slideUpKeyframes};
  animation-duration: 1250ms;
  animation-timing-function: ease-in;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const animations = {
  deck,
  deckKeyframes,
  fadeIn,
  fadeInKeyframes,
  fadeInKeyframesTo,
  fadeOut,
  fadeOutKeyframes,
  fadeOutKeyframesTo,
  progress,
  progressKeyframes,
  progressFade,
  progressFadeKeyframes,
  slideUp,
  slideUpKeyframes,
};

export default animations;
