const links = {
  canny: "https://sablier.canny.io/feature-requests",
  customers: {
    astaria: "https://twitter.com/AstariaXYZ",
    nouns: "https://nouns.wtf/vote/143",
    reflexer: "https://reflexer.finance/",
    rook: "https://medium.com/sablier/case-study-rook-labs-f03ce374c096",
    shapeshift: "https://medium.com/sablier/case-study-shapeshift-8cf44096b9e",
  },
  discord: "https://discord.gg/bSwRCwWRsT",
  docs: {
    proxy: "https://github.com/PaulRBerg/prb-proxy",
    v1: "https://docs.sablier.com",
    v2: "https://docs.sablier.com",
    v2Proxy:
      "https://docs.sablier.com/contracts/v2/reference/diagrams#set-up-proxy",
    v2Streaming: "https://docs.sablier.com/concepts/protocol/streaming",
  },
  email: {
    contact: "contact@sablier.com",
    legal: "legal@sablier.com",
    sales: "tech@sablier.com",
    tech: "tech@sablier.com",
  },
  forms: {
    onboarding: "https://forms.gle/LUgTqmhHirs5bQDP9",
    sales: "https://forms.gle/qEnbrzTrq6MSQRMr7",
  },
  github: {
    organization: "https://github.com/sablier-labs",
    raw: {
      chains:
        "https://raw.githubusercontent.com/sablier-labs/assets/main/chains/",
    },
  },
  labs: {
    landing: "https://sablierlabs.co.uk",
  },
  legal: {
    privacy: "https://files.sablier.com/privacy-policy.pdf",
    risk: "https://files.sablier.com/risk-notice.pdf",
    terms: "https://files.sablier.com/terms-of-service.pdf",
  },
  linktree: "https://linktr.ee/SablierLabs",
  medium: "https://medium.com/sablier",
  nft: {
    opensea: (
      chain: string | undefined,
      contract: string | undefined,
      id: string | undefined,
    ) => `https://opensea.io/assets/${chain}/${contract}/${id}`,
  },
  security: {
    audits: "https://github.com/sablier-labs/audits",
    bounty: "https://github.com/sablier-labs/v2-core/blob/main/SECURITY.md",
    code: "https://github.com/sablier-labs/v2-core",
  },
  trm_screening_endpoint:
    "https://api.trmlabs.com/public/v1/sanctions/screening",
  twitter: {
    product: "https://twitter.com/Sablier",
    username: "@Sablier",
  },
  v1: {
    app: "https://v1-app.sablier.com",
    landing: "https://v1.sablier.com",
    pay: "https://v1-pay.sablier.com",
    safe: "",
  },
  v2: {
    client: "https://app.sablier.com",
    clientDemo: "https://app.sablier.com/stream/LL-5-78",
    landing: "https://sablier.com",
    landingLabs: "https://sablierlabs.co.uk",
    safe: "",
  },
  video: {
    intro: "https://youtu.be/0XvJ112Jf1k",
  },
  vendor: {
    crisp: "https://crisp.chat/en/",
    mixpanel: "https://mixpanel.com/",
    sentry: "https://sentry.io/welcome/",
    vercel: "https://vercel.com/docs/concepts/analytics/privacy-policy",
  },
};

export default links;
