import { useMemo } from "react";
import {
  ThemeProvider as Provider,
  createGlobalStyle,
} from "styled-components";
import { normalize } from "styled-normalize";
import type { PropsWithChildren } from "react";
import animations from "./animations";
import attributes from "./attributes";
import colors from "./colors";
import fonts, { nextFonts } from "./fonts";
import gradients, { Definitions as GradientDefinitions } from "./gradients";
import medias from "./medias";
import sizes from "./sizes";
import styles from "./styles";

interface Props {
  variant?: "light" | "dark";
}

export type ThemeType = {
  animations: typeof animations;
  attributes: typeof attributes;
  colors: typeof colors.dark;
  gradients: typeof gradients;
  fonts: typeof fonts;
  medias: typeof medias;
  sizes: typeof sizes;
  styles: typeof styles;
};

declare module "styled-components" {
  export interface DefaultTheme extends ThemeType {}
}

function getTheme(variant?: Props["variant"]): ThemeType {
  const base = {
    animations,
    attributes,
    colors: colors.dark,
    gradients,
    fonts,
    medias,
    sizes,
    styles,
  };

  if (variant === "light") {
    base.colors = colors.light;
  }

  return base;
}

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
 ${normalize}

  :root {
    --font-roboto-mono: ${nextFonts.roboto.style.fontFamily};
    --font-urbanist: ${nextFonts.urbanist.style.fontFamily};
    --font-catamaran: ${nextFonts.catamaran.style.fontFamily};
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
    line-height: 1;
  }

  html {
    ${Object.entries(colors.dark)
      .map(([key, value]) => `--color-${key}: ${value};`)
      .join(" ")}
  }

   /** https://makandracards.com/makandra/55801-does-html-or-body-scroll-the-page */

  body{
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    scroll-behavior: smooth;
  }

  html,
  body {
    min-height: 100vh;
    margin: 0rem;
    padding: 0rem;
    color: ${(props) => props.theme.colors.text};
    font-size: 16px;
    font-family: ${fonts.primary};
    background-color: ${(props) => props.theme.colors.background};
    cursor: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;


    #root, #__next {
      width: 100%;
      overflow: hidden;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    border: none;
    border-image-width: 0px;
    outline: none;
    appearance: none;

    &:active, &:focus{
      border: none;
      border-image-width: 0px;
      outline: none;
      appearance: none;
    }

  }

  p, span, h1, h2, h3, h4, h5, label{
    margin: 0;
    -webkit-margin-before: 0px;
  }

  input[type="number"]:-webkit-inner-spin-button,
  input[type="number"]:-webkit-outer-spin-button {
    appearance: none;
  }
  input:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: both;
  }

  #WEB3_CONNECT_MODAL_ID > div {
    z-index: ${sizes.zIndexModal + 1};
  }

  .ant-popover {
    .ant-popover-content{
      .ant-popover-inner-content{
        color: ${(props) => props.theme.colors.white};
        font-weight: 500;
        font-size: 10pt;
        font-family: ${(props) => props.theme.fonts.primary};
        line-height: 14pt;
        p{
          line-height: 14pt;
        }
      }
    }
  }
`;

function Definitions() {
  return (
    <>
      <GradientDefinitions />
    </>
  );
}

function ThemeProvider({
  children,
  variant = "dark",
}: PropsWithChildren<Props>) {
  const value = useMemo(() => getTheme(variant), [variant]);

  return (
    <Provider theme={value}>
      <Definitions />
      <GlobalStyle theme={value} />
      <>{children}</>
    </Provider>
  );
}

export { getTheme, GlobalStyle, ThemeProvider };
