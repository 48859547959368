import {
  IAddress,
  IAlias,
  IFilter,
  IMilliseconds,
  ISignerOrProvider,
  IStreamId,
  IValue,
} from "@sablier/v2-types";
import BigNumber from "bignumber.js";
import _ from "lodash";
import numeral from "numeral";
import dayjs from "./dayjs";

const ETHEREUM_ADDRESS_REGEX = new RegExp("^0x[a-fA-F0-9]{40}$");
const ETHEREUM_NAME_REGEX = new RegExp(".*.(eth|xyz)$");
const UNDEFINED = new Object(); // We need it for its object id

function expect(
  value: unknown,
  identifier = "unknown",
  strongType?: string,
): value is NonNullable<typeof value> {
  if (value === undefined || _.isNil(value)) {
    throw new Error(`Missing parameter: ${identifier}`);
  }
  if (!isNilOrEmptyString(strongType) && typeof value !== strongType) {
    throw new Error(
      `Unexpected type for parameter: ${identifier}, ${strongType}, got ${typeof value}`,
    );
  }

  return true;
}

async function extractChainId(library: ISignerOrProvider) {
  try {
    return library.getChainId();
  } catch (error) {
    //
  }
  return undefined;
}

function isEthereumAddress(
  value: string | undefined,
  isPrefixAllowed = false,
): boolean {
  if (isNilOrEmptyString(value)) {
    return false;
  }
  if (isPrefixAllowed && isPrefixedAddress(value)) {
    return true;
  }
  return ETHEREUM_ADDRESS_REGEX.test(value);
}

function isPrefixedAddress(value: string | undefined): boolean {
  if (isNilOrEmptyString(value)) {
    return false;
  }
  if (!value.includes(":")) {
    return false;
  }
  try {
    const slices = value.split(":");
    if (slices.length !== 2) {
      return false;
    }
    return isEthereumAddress(value.replace(slices[0].concat(":"), "0x"));
  } catch (error) {
    return false;
  }
}

function isEthereumName(value: string | undefined): boolean {
  if (isNilOrEmptyString(value)) {
    return false;
  }
  if (isEthereumAddress(value)) {
    return false;
  }
  return ETHEREUM_NAME_REGEX.test(value);
}

function isNilOrEmptyString(value: unknown): value is null | undefined | "" {
  if (typeof value === "bigint") {
    return false;
  }

  return _.isNil(value) || _.toString(value).length === 0;
}

async function attemptAsync<TResult>(
  func: (...args: unknown[]) => TResult,
  ...args: unknown[]
) {
  try {
    return await func(...args);
  } catch (e) {
    return null;
  }
}

function isWindow(): boolean {
  return typeof window !== "undefined";
}

function _cleanup(value: unknown, replace: typeof _replace) {
  const reference = value as { [key: string]: unknown };
  Object.keys(reference).forEach((key) => {
    if (!_.isArray(reference[key])) {
      reference[key] = replace(reference[key]);
    }
  });

  return value;
}

function _replace(value: unknown): unknown {
  return value === UNDEFINED
    ? undefined
    : _.isArray(value)
    ? value.map(_replace)
    : _.isObject(value) && !_.isNil(value)
    ? _cleanup(value, _replace)
    : value;
}

function _customizer(_sourceItem: unknown, additionalItem: unknown) {
  if (_.isArray(additionalItem)) {
    return additionalItem;
  }

  if (additionalItem === undefined) {
    return UNDEFINED;
  }
}

function mergeWithLogic(source: unknown, additional: unknown): unknown {
  _.mergeWith(source, additional, _customizer);
  _cleanup(source, _replace);

  return source;
}

//Bignumber.js does not offer pow with fractional exponents
function powWithFractional({
  base,
  exponent,
}: {
  base: BigNumber;
  exponent: BigNumber;
}): BigNumber {
  const lnBase = Math.log(base.toNumber());
  const result = Math.exp(exponent.toNumber() * lnBase);
  return new BigNumber(result);
}

function sleep(ms = 1000): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function toAddress(value: string | null | undefined): string {
  if (!isNilOrEmptyString(value)) {
    if (isEthereumAddress(value)) {
      return value.toLowerCase();
    }
    if (isPrefixedAddress(value)) {
      const slices = value.split(":");
      return value.replace(slices[0].concat(":"), "0x").toLowerCase();
    }
  }
  return "";
}

function toAlias(value: IAlias | undefined): IAlias {
  return isNilOrEmptyString(value) ? "" : _.toUpper(value);
}

/**
 * Arguments for toFixed are intentionally omitted to
 * force an unrounded, normal notation as per numeral's docs.
 **/
function toBigInt(value?: bigint | number | string | BigNumber): bigint {
  if (isNilOrEmptyString(value)) {
    return 0n;
  }

  if (typeof value == "bigint") {
    return value;
  }

  const formatted = new BigNumber(value);

  return BigInt(formatted.toFixed());
}

function toDayjs(milliseconds?: IMilliseconds): ReturnType<typeof dayjs> {
  try {
    if (_.isNil(milliseconds) || _.toString(milliseconds).length === 0) {
      return dayjs();
    }
    return dayjs(new BigNumber(milliseconds).toNumber());
  } catch (error) {
    console.debug(error);
  }
  return dayjs();
}

type Item = [number, string, string];

function toDuration(
  milliseconds: IMilliseconds | number | undefined,
  purpose:
    | "date"
    | "date-estimate"
    | "date-full"
    | "date-full-estimate"
    | "date-only"
    | "date-minimal"
    | "date-short"
    | "date-short-estimate"
    | "time"
    | "time-short"
    | "time-estimate"
    | "time-full",
): [string, Item[]] {
  const source = _.toString(milliseconds || "0");

  if (purpose === "time") {
    return _toDurationTime(source, ["minute", "hour", "day", "month", "year"]);
  }

  if (purpose === "time-short") {
    return _toDurationTime(source, ["hour", "day", "year"]);
  }

  if (purpose === "time-estimate") {
    return _toDurationTimeEstimate(source);
  }

  if (purpose === "time-full") {
    return _toDurationTime(source, [
      "second",
      "minute",
      "hour",
      "day",
      "month",
      "year",
    ]);
  }

  if (purpose === "date-full") {
    return _toDurationDate(source, false, true, true, true);
  }
  if (purpose === "date-full-estimate") {
    return _toDurationDate(source, true, true, true, true);
  }

  if (purpose === "date") {
    return _toDurationDate(source, false, false, true, true);
  }
  if (purpose === "date-estimate") {
    return _toDurationDate(source, true, false, true, true);
  }

  if (purpose === "date-short") {
    return _toDurationDate(source, false, false, false, true);
  }
  if (purpose === "date-short-estimate") {
    return _toDurationDate(source, true, false, false, true);
  }
  if (purpose === "date-minimal") {
    return _toDurationDate(source, false, false, false, false, false);
  }
  if (purpose === "date-only") {
    return _toDurationDate(source);
  }

  return ["", []];
}

function _toDurationDate(
  milliseconds: IMilliseconds,
  isEstimate = false,
  isSeconded = false,
  isMinuted = false,
  isHoured = false,
  isYeared = true,
): [string, Item[]] {
  const format = (() => {
    const base = isYeared ? `MMM DD 'YY` : `MMM DD`;
    const parts = [
      isHoured ? "h" : undefined,
      isMinuted ? "mm" : undefined,
      isSeconded ? "ss" : undefined,
    ]
      .filter((part) => !_.isNil(part))
      .join(":");

    const symbol = parts.length ? (isEstimate ? "~" : "@") : undefined;
    const extension = parts.length ? "a" : undefined;

    return [base, symbol, parts, extension]
      .filter((part) => !_.isNil(part))
      .join(" ");
  })();

  return [dayjs(_.toNumber(milliseconds)).format(format), []];
}

function _toDurationTime(
  milliseconds: IMilliseconds,
  particles: string[] = ["minute", "hour", "day", "month", "year"],
): [string, Item[]] {
  try {
    if (!new RegExp(/^[0-9]+$/).test(milliseconds)) {
      throw new Error("Source value is not a number.");
    }

    const base = _.toNumber(milliseconds);
    /**
     * There used to be an error with durations that take into account DST. Switching to UTC time fixes it.
     * E.g. user selects 30 days, the diff here approximates 30 days and 1 hour.
     *
     * https://day.js.org/docs/en/manipulate/utc-offset
     * https://day.js.org/docs/en/parse/utc
     */
    const now = dayjs.utc();
    const end = now.add(base, "milliseconds");

    const years: Item = [
      particles.includes("year") ? end.diff(now, "year") : 0,
      "year",
      "years",
    ];
    const months: Item = [
      particles.includes("month")
        ? end.diff(now.add(years[0], "years"), "month")
        : 0,
      "month",
      "months",
    ];
    const days: Item = [
      particles.includes("day")
        ? end.diff(now.add(years[0], "years").add(months[0], "months"), "day")
        : 0,
      "day",
      "days",
    ];

    const hours: Item = [
      particles.includes("hour")
        ? end.diff(
            now
              .add(years[0], "years")
              .add(months[0], "months")
              .add(days[0], "days"),
            "hour",
          )
        : 0,
      "hour",
      "hours",
    ];
    const minutes: Item = [
      particles.includes("minute")
        ? end.diff(
            now
              .add(years[0], "years")
              .add(months[0], "months")
              .add(days[0], "days")
              .add(hours[0], "hours"),
            "minute",
          )
        : 0,
      "minute",
      "minutes",
    ];

    const seconds: Item = [
      particles.includes("second")
        ? end.diff(
            now
              .add(years[0], "years")
              .add(months[0], "months")
              .add(days[0], "days")
              .add(hours[0], "hours")
              .add(minutes[0], "minutes"),
            "second",
          )
        : 0,
      "second",
      "seconds",
    ];

    const items = [years, months, days, hours, minutes, seconds];

    const result = items
      .filter((item) => item[0] !== 0)
      .slice(0, 3)
      .map((item) =>
        [_.toString(item[0]), item[item[0] === 1 ? 1 : 2]].join(" "),
      )
      .join(" ");

    return [result, items];
  } catch (error) {
    console.debug("Duration formatting failed.", error);
  }

  return ["", []];
}

function _toDurationTimeEstimate(milliseconds: string): [string, Item[]] {
  const base = _.toNumber(milliseconds);
  const now = dayjs();
  const end = now.add(base, "milliseconds");

  return [end.fromNow(true), []];
}

/**
 * Arguments for toFixed are intentionally omitted to
 * force an unrounded, normal notation as per numeral's docs.
 **/
function toNumeralPrice(
  value?: number | string | null | BigNumber,
  dollar = false,
  abbreviation = true,
): string {
  if (_.isNil(value)) {
    return "0";
  }

  const prefix = dollar ? "$" : "";
  const instance = new BigNumber(value);

  if (instance.isZero()) {
    return `${prefix}0`;
  }

  if (
    instance.absoluteValue().isLessThan(new BigNumber(0.1).pow(6)) ||
    _.isNaN(value)
  ) {
    return `<${prefix}0`;
  }

  if (instance.absoluteValue().isLessThan(new BigNumber(0.001))) {
    return numeral(instance.toFixed()).format(`${prefix}0,0[.][000000]`);
  }
  if (instance.absoluteValue().isLessThan(new BigNumber(1))) {
    return numeral(instance.toFixed()).format(`${prefix}0,0[.][0000]`);
  }

  if (instance.absoluteValue().isGreaterThan(9999)) {
    if (abbreviation) {
      return numeral(instance.toFixed())
        .format(`${prefix}0,0[.][00]a`)
        .toUpperCase();
    }

    return numeral(instance.toFixed())
      .format(`${prefix}0,0[.][0000]`)
      .toUpperCase();
  }

  return numeral(instance.toFixed()).format(`${prefix}0,0[.][0000]`);
}

function toPrefix(
  value: number | string,
  prefix: string,
  isRepeatAllowed = false,
): string {
  if (isNilOrEmptyString(value)) {
    return value;
  }
  if (!isRepeatAllowed && _.toString(value).startsWith(prefix)) {
    return _.toString(value);
  }

  return `${prefix}${value}`;
}

function toSuffix(
  value: number | string,
  suffix: string,
  isRepeatAllowed = false,
): string {
  if (isNilOrEmptyString(value)) {
    return value;
  }
  if (!isRepeatAllowed && _.toString(value).endsWith(suffix)) {
    return _.toString(value);
  }

  return `${value}${suffix}`;
}

function toShort(value: string | undefined, a = 5, b = -3): string {
  if (!isNilOrEmptyString(value)) {
    if (value.length - (a - 2) < a) {
      return value;
    }

    return value.slice(0, a).concat("..").concat(value.slice(b));
  }

  return "";
}

function toShortAddress(value: IAddress | undefined, a = 5, b = -4): string {
  if (!isNilOrEmptyString(value)) {
    if (value.length - 3 < a) {
      return value;
    }

    if (isEthereumAddress(value)) {
      return value.slice(0, a).concat("..").concat(value.slice(b));
    }
    if (isPrefixedAddress(value)) {
      const prefix = value.split(":")[0];
      return value.slice(0, prefix.length + 1 + a);
    }
  }

  return "";
}

function toShortName(value: string | undefined, a = 5, b = -3): string {
  if (!isNilOrEmptyString(value) && isEthereumName(value)) {
    if (value.length <= a + Math.abs(b) + 2) {
      return value;
    }

    return value
      .replace(new RegExp(".eth$"), "")
      .slice(0, a)
      .concat("..")
      .concat(value.slice(b));
  }

  return "";
}

function toShortAddressOrName(
  value: IAddress | string | undefined,
  a?: number,
  b?: number,
): string {
  const address = toShortAddress(value, a, b);
  const name = toShortName(value, a, b);

  if (!isNilOrEmptyString(name)) {
    return name;
  }
  return address;
}

function toFilter({
  chainId,
  sender,
  recipient,
  streamIds,
}: {
  chainId?: number | string | undefined;
  sender?: IAddress | undefined;
  recipient?: IAddress | undefined;
  streamIds?: IStreamId[] | undefined;
} = {}): IFilter {
  const filter: IFilter = {
    chainId: undefined,
    sender: undefined,
    recipient: undefined,
    streamIds: undefined,
  };

  if (!isNilOrEmptyString(chainId)) {
    filter.chainId = _.toNumber(chainId) || undefined;
  }

  if (!isNilOrEmptyString(sender)) {
    filter.sender = toAddress(sender);
  }

  if (!isNilOrEmptyString(recipient)) {
    filter.recipient = toAddress(recipient);
  }

  if (!isNilOrEmptyString(streamIds)) {
    if (_.isArray(streamIds) && streamIds.length > 0) {
      filter.streamIds = streamIds.map((item) => item || "");
    }
  }

  return filter;
}

function toPercentage(
  source?: string | number | BigNumber | undefined,
): string {
  let base = "0.0";

  if (!isNilOrEmptyString(source)) {
    const value = new BigNumber(source || "0");
    if (!value.isZero()) {
      if (value.isLessThan(new BigNumber(0.2))) {
        base = ".".concat(BigNumber.max(value, 0.01).toFixed(2).split(".")[1]);
      } else {
        base = value.isLessThan(new BigNumber(9))
          ? value.toFixed(1)
          : value.toFixed(0);
      }
    }
  }

  return `${base}%`;
}

function toValue({
  decimals,
  humanized,
  label,
  raw,
}: {
  decimals: number | string | BigNumber | undefined;
  /** The humanized value takes priority if both raw and humanized are available */
  humanized?: string | BigNumber;
  raw?: string | BigNumber;
  label?: string;
}): IValue {
  try {
    decimals = isNilOrEmptyString(decimals) ? 0 : decimals;
    const precision = new BigNumber(decimals).toNumber();
    const fixision = precision > 0 ? precision : 0;

    if (!isNilOrEmptyString(humanized)) {
      const source = new BigNumber(humanized);
      const padding = new BigNumber(10).pow(decimals);

      const h = source.toFixed(fixision);
      const r = source.times(padding).toFixed(0);

      return {
        humanized: new BigNumber(h),
        label,
        raw: new BigNumber(r),
      };
    } else if (!isNilOrEmptyString(raw)) {
      const source = new BigNumber(raw);
      const padding = new BigNumber(10).pow(precision);

      const h = source.dividedBy(padding).toFixed(fixision);
      const r = source.toFixed(0);

      return {
        humanized: new BigNumber(h),
        label,
        raw: new BigNumber(r),
      };
    }
  } catch (error) {
    console.debug(error);
  }
  return {
    humanized: new BigNumber(0),
    label,
    raw: new BigNumber(0),
  };
}

function toValuePrepared({
  decimals,
  humanized,
  raw,
}: {
  decimals: number | string | BigNumber | undefined;
  humanized?: string | BigNumber;
  raw?: string | BigNumber;
}): string {
  const value = toValue({ decimals, humanized, raw });
  return value ? value.raw.toFixed(0).toString() : "0";
}

export interface LoDashMixins extends _.LoDashStatic {
  attemptAsync<TResult>(
    func: (...args: unknown[]) => TResult,
    ...args: unknown[]
  ): Promise<TResult | null>;

  expect(
    value: unknown,
    identifier?: string,
    strongType?: string,
  ): value is NonNullable<typeof value>;

  extractChainId(library: ISignerOrProvider): Promise<number | undefined>;

  isEthereumAddress(
    value: string | undefined,
    isPrefixAllowed?: boolean,
  ): boolean;

  isEthereumName(value: string | undefined): boolean;

  isNilOrEmptyString(value: unknown): value is null | undefined | "";

  isPrefixedAddress(value: string | undefined): boolean;

  isWindow(): boolean;

  mergeWithLogic(source: unknown, additional: unknown): unknown;

  powWithFractional(value: { base: BigNumber; exponent: BigNumber }): BigNumber;

  sleep(ms?: number): Promise<void>;

  toAlias(value: IAlias | undefined): IAlias;

  toAddress(value: string | null | undefined): string;

  toBigInt(value?: bigint | number | string | BigNumber): bigint;

  toDayjs(milliseconds?: IMilliseconds): ReturnType<typeof dayjs>;

  toDuration(
    milliseconds: IMilliseconds | number | undefined,
    purpose:
      | "date"
      | "date-estimate"
      | "date-full"
      | "date-full-estimate"
      | "date-only"
      | "date-minimal"
      | "date-short"
      | "date-short-estimate"
      | "time"
      | "time-short"
      | "time-estimate"
      | "time-full",
  ): [string, [number, string, string][]];

  toFilter(filter: {
    chainId?: number | string | undefined;
    sender?: IAddress | undefined;
    recipient?: IAddress | undefined;
    proxy?: IAddress | undefined;
    streamIds?: IStreamId[] | undefined;
  }): IFilter;

  toNumeralPrice(
    value?: number | string | null | BigNumber,
    dollar?: boolean,
    abbreviation?: boolean,
  ): string;

  toPrefix(
    value: number | string,
    prefix: string,
    isRepeatAllowed?: boolean,
  ): string;

  toSuffix(
    value: number | string,
    suffix: string,
    isRepeatAllowed?: boolean,
  ): string;

  toShort(value: string | undefined, a?: number, b?: number): string;

  toShortAddress(value: string | undefined, a?: number, b?: number): string;

  toShortName(value: string | undefined, a?: number, b?: number): string;

  toShortAddressOrName(
    value: string | undefined,
    a?: number,
    b?: number,
  ): string;

  toPercentage(source?: string | number | BigNumber | undefined): string;

  toValue(value: {
    decimals: number | string | BigNumber | undefined;
    humanized?: string | BigNumber;
    raw?: string | BigNumber;
    label?: string;
  }): IValue;

  toValuePrepared(value: {
    decimals: number | string | BigNumber | undefined;
    humanized?: string | BigNumber;
    raw?: string | BigNumber;
  }): string;
}

_.mixin({ attemptAsync });
_.mixin({ expect });
_.mixin({ extractChainId });
_.mixin({ isEthereumAddress });
_.mixin({ isEthereumName });
_.mixin({ isNilOrEmptyString });
_.mixin({ isPrefixedAddress });
_.mixin({ isWindow });
_.mixin({ mergeWithLogic });
_.mixin({ powWithFractional });
_.mixin({ sleep });
_.mixin({ toAddress });
_.mixin({ toAlias });
_.mixin({ toBigInt });
_.mixin({ toDayjs });
_.mixin({ toDuration });
_.mixin({ toFilter });
_.mixin({ toNumeralPrice });
_.mixin({ toPercentage });
_.mixin({ toPrefix });
_.mixin({ toSuffix });
_.mixin({ toShort });
_.mixin({ toShortAddress });
_.mixin({ toShortAddressOrName });
_.mixin({ toShortName });
_.mixin({ toValue });
_.mixin({ toValuePrepared });

// See https://stackoverflow.com/a/41832451/3873510
export default _ as LoDashMixins;
