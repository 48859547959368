import { _ } from "@sablier/v2-mixins";
import type { IEnvironmentVariable, TRMResponse } from "@sablier/v2-types";
import { blacklist } from "./blacklist";
import links from "./links";

export async function requestTRMScreening(
  address: string | undefined,
): Promise<Array<TRMResponse>> {
  if (_.isNilOrEmptyString(address)) {
    throw new Error("Missing address.");
  }

  if (blacklist.some((item) => _.toAddress(item) === _.toAddress(address))) {
    return [
      {
        address,
        isSanctioned: true,
      },
    ];
  }
  const headers = new Headers();
  const username = (process.env.NEXT_PUBLIC_TRM_SANCTIONS_API_KEY ||
    "") satisfies IEnvironmentVariable;
  const password = username;

  headers.set("Content-Type", "application/json");
  headers.set(
    "Authorization",
    `Basic ${Buffer.from(username.concat(":").concat(password)).toString(
      "base64",
    )}`,
  );

  const response = await fetch(links.trm_screening_endpoint, {
    credentials: "omit",
    headers,
    method: "POST",
    body: JSON.stringify([{ address }]),
  });

  if (!response.ok) {
    const error = new Error(
      "TRM V1 request failed with status " + response.status,
    );
    throw error;
  }

  return response.json();
}
