import type { Locale } from "@sablier/v2-locales";

export const ActionCategory = {
  APPROVAL: "Approval",
  APPROVAL_FOR_ALL: "ApprovalForAll",
  CREATE: "Create",
  CANCEL: "Cancel",
  FLASH: "FlashLoan",
  RENOUNCE: "Renounce",
  TRANSFER: "Transfer",
  WITHDRAW: "Withdraw",
} as const;

export const StreamCategory = {
  LOCKUP_LINEAR: "LockupLinear",
  LOCKUP_DYNAMIC: "LockupDynamic",
} as const;

export const PeripheryCategory = {
  PERMIT2: "permit2",
  PLUGIN: "plugin",
  REGISTRY: "registry",
  TARGET: "target",
  WITHDRAWER_TARGET: "withdrawerTarget",
};

/**
 * -------- STATUS MACHINE | THE PRIMARY FLOW  --------
 *
 * NULL
 * └──── PENDING            ┌─── SETTLED ─── DEPLETED
 *       └──── STREAMING ───│
 *                          └─── CANCELED ─── DEPLETED
 *
 * Status definitions extracted from https://github.com/sablier-labs/v2-core/blob/239a695e242d0f395d8afdb77777d39c6211a2d2/src/types/DataTypes.sol#L50
 * ┌───────────────────────────────────────────────────────────────────┐
 * | Status     | Description                                          |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | NULL       | Missing instance. Externally handled, as there's     |
 * |            | no point in creating an empty Stream entity          |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | PENDING    | Stream created but not started; assets are in a      |
 * |            | pending state                                        |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | STREAMING  | Stream where assets are currently being streamed     |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | SETTLED    | All assets have been streamed; recipient is due      |
 * |            | to withdraw them                                     |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | CANCELED   | Stream has been interrupted. Remaining assets        |
 * |            | await recipient's withdrawal                         |
 * | ────────── | ─────────────────────────────────────────────────────|
 * | DEPLETED   | All assets have been withdrawn and/or refunded       |
 * └───────────────────────────────────────────────────────────────────┘
 *
 * The app will make no use of the NULL status (handled externally,
 * see useCurrentStream) as well as split the final state into two
 * separate endpoints for the maximum amount of extractable
 * data from the status.
 *
 * Read the "statuses" section in the docs for more context.
 */

export const StreamStatus = {
  PENDING: "Pending",
  STREAMING: "Streaming",
  CANCELED: "Canceled",
  SETTLED: "Settled",
  /** --- END STATES --- */
  DEPLETED_SETTLED: "DepletedSettled",
  DEPLETED_CANCELED: "DepletedCanceled ",
} as const;

export type ActionCategory =
  (typeof ActionCategory)[keyof typeof ActionCategory];

export type PeripheryCategory =
  (typeof PeripheryCategory)[keyof typeof PeripheryCategory];
export type StreamCategory =
  (typeof StreamCategory)[keyof typeof StreamCategory];
export type StreamStatus = (typeof StreamStatus)[keyof typeof StreamStatus];

/**
 * ------------------------------------------------------------
 * STREAM SHAPE - Naming conventions
 *
 * - LockupLinear streams will have simple names ["linear", "cliff"]
 * - LockupDynamic streams will be prefixed with the "dynamic" particle
 * - LockupDynamic streams without bespoke names (e.g. "timelock") will follow the order of their segments in the name (e.g. "cliff" + "exponential")
 * ------------------------------------------------------------
 */
export type StreamShape =
  | "cliff"
  | "linear"
  | "dynamicCliffExponential"
  | "dynamicExponential"
  | "dynamicStepper"
  | "dynamicTimelock"
  | "dynamicUnlockLinear"
  | "dynamicUnlockCliff"
  | "unknown";

export const StreamShapes: Record<
  StreamShape,
  {
    description: Locale;
    id: StreamShape | string;
    isUnknown?: true;
    label: Locale;
    title: Locale;
  }
> = {
  cliff: {
    description: "structs.onCliff",
    id: "cliff",
    label: "words.cliff",
    title: "structs.cliffTitle",
  },
  dynamicUnlockCliff: {
    description: "structs.onDynamicUnlockCliff",
    id: "dynamicUnlockCliff",
    label: "structs.dynamicUnlockCliff",
    title: "structs.dynamicUnlockCliffTitle",
  },
  linear: {
    description: "structs.onLinear",
    id: "linear",
    label: "words.linear",
    title: "structs.linearTitle",
  },
  dynamicUnlockLinear: {
    description: "structs.onDynamicUnlockLinear",
    id: "dynamicUnlockLinear",
    label: "structs.dynamicUnlockLinear",
    title: "structs.dynamicUnlockLinearTitle",
  },
  dynamicExponential: {
    description: "structs.onDynamicExponential",
    id: "dynamicExponential",
    label: "words.exponential",
    title: "structs.exponentialTitle",
  },
  dynamicCliffExponential: {
    description: "structs.onDynamicCliffExponential",
    id: "dynamicCliffExponential",
    label: "structs.dynamicCliffExponential",
    title: "structs.dynamicCliffExponentialTitle",
  },
  dynamicStepper: {
    description: "structs.onDynamicStepper",
    id: "dynamicStepper",
    label: "structs.dynamicStepper",
    title: "structs.stepperTitle",
  },
  dynamicTimelock: {
    description: "structs.onDynamicTimelock",
    id: "dynamicTimelock",
    label: "words.timelock",
    title: "words.timelock",
  },
  unknown: {
    id: "unknown",
    isUnknown: true,
    description: "structs.notDefined",
    title: "words.stream",
    label: "words.stream",
  },
};
