export const DEFAULT_TOKEN_LIST = {
  url: "https://tokens.sablier.io",
  homepage: "https://github.com/sablier-labs/community-token-list",
};

export const OTHER_TOKEN_LISTS = [
  {
    icon: "https://sablier.finance/favicon.ico",
    title: "Community",
    url: "https://tokens.sablier.io",
  },
  {
    icon: "https://www.coingecko.com/favicon.ico",
    title: "CoinGecko",
    url: "https://tokens.coingecko.com/uniswap/all.json",
  },
  {
    icon: "https://logo.assets.tkn.eth.limo/",
    title: "TKN.eth",
    url: "https://list.tkn.eth.limo/",
  },
  {
    icon: "https://cloudflare-ipfs.com/ipfs/QmNa8mQkrNKp1WEEeGjFezDmDeodkWRevGFN8JCV7b4Xir/",
    title: "Uniswap",
    url: "https://tokens.uniswap.org/",
  },
  {
    icon: "https://static.optimism.io/optimism.svg",
    title: "Superchain OP",
    url: "https://static.optimism.io/optimism.tokenlist.json",
  },
];
