const attributes = {
  base: {
    preserveAspectRatio: "xMidYMid meet",
    vectorEffect: "non-scaling-stroke",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
  },
};

export default attributes;
