import { _ } from "@sablier/v2-mixins";
import * as Sentry from "@sentry/nextjs";

export const ERR_SOFT_CODE = [];
export const ERR_SOFT_NAME = ["EstimateGasExecutionError"];
export const ERR_SOFT_MESSAGE = ["User rejected the request."];

function enable() {
  if (process.env.NEXT_PUBLIC_SABLIER_ENV === "production") {
    console.info("%c[vendor]", "color: lightsteelblue", `[sentry]`, "ON");

    const hub = Sentry.getCurrentHub();
    const client = hub.getClient();
    if (client) {
      client.getOptions().enabled = true;
    }
  }
}

function disable() {
  console.info("%c[vendor]", "color: lightsteelblue", `[sentry]`, "OFF");
  void Sentry.close();
}

type LogError = unknown | { message: string };
type LogOmit = {
  code?: (number | string)[];
  message?: string[];
  name?: string[];
};

function log(
  error: LogError,
  omit: LogOmit = {
    code: ERR_SOFT_CODE,
    name: ERR_SOFT_NAME,
    message: ERR_SOFT_MESSAGE,
  },
) {
  console.error(error);
  if (omit?.name && omit.name.length) {
    const name = _.get(error, "name");
    if (
      !_.isNilOrEmptyString(name) &&
      omit.name.find((item) => _.toString(item) === _.toString(name))
    ) {
      return;
    }
  }

  if (omit?.message && omit.message.length) {
    const message = _.get(error, "message");
    if (
      !_.isNilOrEmptyString(message) &&
      omit.message.find((item) => _.toString(item) === _.toString(message))
    ) {
      return;
    }
  }

  if (omit?.code && omit.code.length) {
    const code = _.get(error, "code");
    if (
      !_.isNilOrEmptyString(code) &&
      omit.code.find((item) => _.toString(item) === _.toString(code))
    ) {
      return;
    }
  }

  Sentry.captureException(error);
}

const bundle = {
  disable,
  enable,
  log,
};

export default bundle;
