import { general } from "./colors";

export const palette = {
  dark: `linear-gradient(225deg, ${general.dark150} 0%, ${general.dark300} 100%)`,
  primary: `linear-gradient(225deg, ${general.primaryEnd} 0%, ${general.primaryStart} 100%)`,
  red: `linear-gradient(225deg, ${general.redEnd} 0%, ${general.redStart} 100%)`,
  thumbnail: `linear-gradient(225deg, ${general.thumbnailStart} 0%, ${general.thumbnailEnd})`,
  secondary: `linear-gradient(225deg, ${general.secondaryEnd} 0%, ${general.secondaryStart} 100%)`,
};

const gradients = {
  dark: palette.dark,
  logo: palette.primary,
  primary: palette.primary,
  red: palette.red,
  secondary: palette.secondary,

  svgId: {
    dark: "gradient-dark",
    primary: "gradient-primary",
    red: "gradient-red",
    thumbnail: "gradient-thumbnail",
    secondary: "gradient-secondary",
  },

  svgUrl: {
    dark: "url(#gradient-dark)",
    primary: "url(#gradient-primary)",
    red: "url(#gradient-red)",
    thumbnail: "url(#gradient-thumbnail)",
    secondary: "url(#gradient-secondary)",
  },
};

function Generate({
  id,
  from,
  to,
  transform = "",
}: {
  id: string;
  from: string;
  to: string;
  transform?: string;
}) {
  return (
    <linearGradient gradientTransform={transform} id={id}>
      <stop offset={"0%"} stopColor={from} />
      <stop offset={"100%"} stopColor={to} />
    </linearGradient>
  );
}

export function Definitions() {
  return (
    <svg
      style={{
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
      }}
    >
      <defs>
        <Generate
          id={gradients.svgId.dark}
          from={general.dark300}
          to={general.dark150}
        />{" "}
        <Generate
          id={gradients.svgId.primary}
          from={general.primaryStart}
          to={general.primaryEnd}
        />
        <Generate
          id={gradients.svgId.red}
          from={general.redStart}
          to={general.redEnd}
        />
        <Generate
          id={gradients.svgId.thumbnail}
          from={general.thumbnailStart}
          to={general.thumbnailEnd}
          transform={"rotate(90)"}
        />
        <Generate
          id={gradients.svgId.secondary}
          from={general.secondaryStart}
          to={general.secondaryEnd}
        />
      </defs>
    </svg>
  );
}

export default gradients;
