import { lighten, rgba } from "polished";

const base = "#14161F";

export const palette = {
  black: "#000000",

  /** Dark: variations on HSL's lightness */

  dark000: base,
  dark050: lighten(0.03, base),
  dark100: lighten(0.05, base),
  dark150: lighten(0.065, base),
  dark200: lighten(0.08, base),
  dark250: lighten(0.09, base),
  dark300: lighten(0.11, base),
  dark400: lighten(0.14, base),
  dark500: lighten(0.17, base),
  dark600: lighten(0.2, base),
  dark700: lighten(0.23, base),
  dark800: lighten(0.26, base),
  dark900: lighten(0.29, base),
  dark1000: lighten(0.32, base),

  /** Gray: variations on HSB's brightness */

  gray100: "#E1E4EA",
  gray200: "#C3C9D5",
  gray300: "#A5AEC0",
  gray400: "#8792AB",
  gray500: "#6A7795",

  green: "#00D395",

  primaryEnd: "#FFB800",
  primaryMiddle: "#FF9C00",
  primaryStart: "#FF7300",

  red: "#E52E52",
  redEnd: "#C9183B",
  redDesaturated: "#DB3959",
  redStart: "#E52E52",

  secondaryDesaturated: "#266CD9",
  secondaryEnd: "#00B7FF",
  secondaryMiddle: "#0063FF",
  secondaryStart: "#003dff",

  transparent: "transparent",
  white: "#FFFFFF",
};

export const general = {
  ...palette,

  background: palette.dark100,
  backgroundLights: palette.dark800,
  border: palette.dark300,
  borderLanding: palette.dark100,
  dark: palette.dark000,
  inputBorder: palette.dark400,
  light: palette.white,
  modalBackground: palette.dark100,
  modalDetailsBackground: palette.dark100,
  orange: palette.primaryMiddle,
  text: palette.gray100,
  thumbnailEnd: rgba(palette.primaryMiddle, 0.01),
  thumbnailStart: rgba(palette.primaryMiddle, 0.25),
  yellow: palette.primaryEnd,
  yellow10: rgba(palette.primaryEnd, 0.1),
  yellow50: rgba(palette.primaryEnd, 0.5),
};

export const dark = {
  ...palette,
  ...general,
};

export const light = {
  ...palette,
  ...general,
};

const colors = {
  light,
  dark,
  palette,
};

export default colors;
