/**
 * Tags needed for Cypress, which can be found throughout the DOM as `data-cy` attributes.
 */
export const tags = {
  accountWarning: "account-warning",

  actionAddStream: "action-add-stream",
  actionBannerAccept: "action-banner-accept",
  actionBannerManage: "action-banner-manage",
  actionCancel: "action-cancel",
  actionCreateGroup: "action-create-group",
  actionCreateSingle: "action-create-single",
  actionDurationPicker: "action-duration-picker",
  actionFillIn: "action-fill-in",
  actionMomentPicker: "action-moment-picker",
  actionRenounce: "action-renounce",
  actionTokenPicker: "action-token-picker",
  actionTransfer: "action-transfer",
  actionWithdraw: "action-withdraw",

  auth: "auth",
  banner: "banner",

  coverCancel: "cover-cancel",
  coverRenounce: "cover-renounce",
  coverTransfer: "cover-transfer",
  coverWithdraw: "cover-withdraw",

  modalChainPicker: "modal-chain-selector",
  modalDurationPicker: "modal-duration-picker",
  modalMomentPicker: "modal-moment-picker",
  modalOptions: "modal-options",
  modalSearch: "modal-search",
  modalAutofillCreate: "modal-autofill-create",
  modalTokenPicker: "modal-token-picker",
  modalTransaction: "modal-transaction",

  pageRendered: "rendered-page",

  streamCard: "stream-card",
  streamDisplay: "stream-display",
  streamFallback: "stream-fallback",
  tab: "tab",

  warning: "warning",
  warningAmount: "warning-amount",
  warningEarly: "warning-early",
  warningExpired: "warning-expired",
  warningChain: "warning-chain",
  warningConnect: "warning-connect",
  warningPlugin: "warning-plugin",
  warningProxy: "warning-proxy",
  warningToken: "warning-token",
};
