const sizes = {
  button: "46px",
  buttonMini: "32px",

  content: "1280px",
  edge: "16px",

  // Extremely small devices (approximated)
  deviceXXS: "320px",
  // Extra small devices (approximated)
  deviceXS: "375px",
  // Small devices (landscape phones, 576px and up)
  deviceSM: "576px",
  // Medium devices (tablets, 768px and up)
  deviceMD: "768px",
  // Large devices (desktops, 992px and up)
  deviceLG: "992px",
  // Extra large devices (large desktops, 1200px and up)
  deviceXL: "1200px",

  // Breakpoint for semi-large devices where the UI needs adjusting
  deviceLGBreak: "1050px",

  historyItem: 140,
  icon: "18px",
  inputBox: "56px",
  inputEdge: "12px",
  inputEdgeSmall: "8px",
  inputField: "36px",
  inputSideGap: "8px",
  inputMargin: "12px",

  modalEdge: "32px",
  modalRadius: "16px",
  modalRadiusMini: "10px",
  modalCover: "664px",

  panel: "532px",
  panelMini: "432px",

  streamCircle: 500,
  streamCircleOffset: 36,
  streamCircleDisplay: "124px",

  streamDetailsWidth: "700px",

  streamThumbnailHeight: 120,
  streamThumbnailWidth: 260,

  summary: "400px",
  tab: 76,
  tableRow: 86,
  toolbar: "86px",

  zIndexTooltip: 1_000_000_000,
  zIndexToast: 1_000_000_000,
  zIndexBackdrop: 90_000,
  zIndexModal: 100_000,
  zIndexCover: 99_000,
  zIndexList: 98_000,
  zIndexOver: 20,
  zIndexToolbar: 100,
  zIndexUnder: 10,

  zIndexPageBackground: 0,
  zIndexPageContent: 10,

  // Padded content space width, including edges
  get container() {
    return `${parseInt(this.content, 10) + 2 * parseInt(this.edge, 10)}px`;
  },
  // Extension to container for anything over the limit
  get deviceContainer() {
    return this.container;
  },

  get formEdge() {
    return `calc( 3 / 2 * ${this.edge})`;
  },

  get switchPill() {
    return this.buttonMini;
  },
};

export default sizes;
