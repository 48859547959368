
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { Meta } from "@sablier/v2-components/organisms";
import { links } from "@sablier/v2-constants";
import { useT } from "@sablier/v2-locales";
import { ThemeProvider } from "@sablier/v2-themes";
import { vendors } from "@sablier/v2-utils";
import "cross-fetch";
import { AppProps } from "next/app";
import type { PropsWithChildren } from "react";
vendors.track.init();
function Wrapper({ children }: PropsWithChildren<unknown>) {
    return <ThemeProvider>{children}</ThemeProvider>;
}
function App({ Component }: AppProps) {
    const { t } = useT();
    return (<Wrapper>
      <Meta.Head description={t("about.meta.landingLabs.description")} title={"Sablier Labs"} purpose={"landingLabs"} domain={links.labs.landing}/>
      <Component />
    </Wrapper>);
}
const __Next_Translate__Page__18baf175d7c__ = App;

    export default __appWithI18n(__Next_Translate__Page__18baf175d7c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      
    });
  