import { BigNumber, _ } from "@sablier/v2-mixins";
import type { IValue } from "@sablier/v2-types";
import chains from "./chains";

export const BASE_INPUT_PRECISION = 6;
export const DEFAULT_BROKER_ADDRESS =
  "0x0000000000000000000000000000000000000000";

export const DEFAULT_BROKER_FEE_PERCENTAGE = "0";
export const DEFAULT_CHAIN_ID = chains.mainnet.chainId;
export const DEFAULT_GAS_LIMIT = 300_000;
export const DEFAULT_GAS_BUMP = 50_000;
export const DEFAULT_DECIMALS = 18;

export const MAX_AMOUNT = new BigNumber(10).pow(20).toString();
export const MAX_AMOUNT_PADDED = (padding = 10) =>
  new BigNumber(10 ** padding).pow(18).toString();
export const DEFAULT_RESET_SLEEP = 9 * 1000;
export const DEFAULT_SAFE_POLLING_SLEEP = 5 * 1000;
export const DEFAULT_UI_POLLING = 5 * 1000;
export const DEFAULT_WITHDRAW_SLIPPAGE = 5;
export const PERMIT2_DOMAIN_NAME = "Permit2";

export const DEFAULT_CLIFF_EXCESS = 59 * 1000; /* ~ 1 minute */
export const DEFAULT_END_DATE_EXCESS = 29 * 60 * 1000; /* ~ 30 minutes */

export const QUERY_CACHE_BLOCK_TIME = 13 * 1000;
export const QUERY_CACHE_ENS_TIME = 60 * 60 * 1000;
export const QUERY_CACHE_TIME = 24 * 60 * 1000;
export const QUERY_PERSIST_TIME = 30 * 24 * 60 * 60 * 1000;

export const EXPONENT_DECIMALS = 18;
export const FAST_UI_POLLING = 0.5 * 1000;
export const HISTORY_PAGE_SIZE = 21;
export const STREAMS_PAGE_SIZE = 31;

export const MAX_CANCEL_STREAM_SET = 60;
export const MAX_WITHDRAW_STREAM_SET = 60;
export const MAX_GROUP_STREAM_SIZE = 60;

export const MAX_SUBGRAPH_ID = new BigNumber(10).pow(9).toString();
export const MAX_SAFE_TX_ATTEMPTS = 100;

export const MODAL_PORTAL_ID = "sablier-modal__container";
export const PRO_STEPPER_MAX_STEPS = 20;
export const PRO_STEPPER_MIN_STEPS = 1;

export const SUBGRAPH_FALLBACK_PARTY = "0x0611B";

export const REQUEST_ID = {
  expected: ["expected"],
  polling: ["polling"],
  stream: ["stream"],
  streamActions: ["stream", "actions"],
  streamItem: ["stream", "item"],
  streamItemNFT: ["stream", "item", "nft"],
  streamItemPreview: ["stream", "item", "preview"],
  streamItemRaw: ["stream", "item", "raw"],
  streamItemWithdrawable: ["stream", "item", "withdraw"],
  streamItemsWithdrawable: ["stream", "items", "withdraw"],
  streamList: ["stream", "list"],
  streamListOwned: ["stream", "list", "owned"],
  streamListSearch: ["stream", "list", "search"],
  streamListIdentifiers: ["stream", "list", "identifiers"],
  tokenList: ["token", "list"],
  trm: ["trm"],
  withdrawable: ["withdrawable"],
  persisted: ["trm"],
  plugin: ["plugin"],
  proxy: ["proxy"],
};

export enum DigitPurpose {
  ABBREVIATION = "abbreviation",
  CHARACTERISTIC = "characteristic",
  DIVIDER = "divider",
  MANTISSA = "mantissa",
  PREFIX = "prefix",
  SUFFIX = "suffix",
}

export const DigitLimits = {
  ABBREVIATE_PRECISION: 10,
  MANTISSA_PRECISION_FLOOR: 3,
  MANTISSA_PRECISION: 8,
  MANTISSA_SLICE_PRECISION: 6,
  STATIC_PRECISION: [5, 7], // Don't change the number (outside of prefix/suffix) until it reaches 5 characteristic and 7 mantissa digits
};

export const ZERO = (
  decimals: BigNumber | number | string = DEFAULT_DECIMALS,
): IValue => _.toValue({ decimals, raw: "0" });
