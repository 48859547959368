import chains from "./chains";

const flash: Record<number, { address: string; symbol: string }[]> = {
  [chains.mainnet.chainId]: [
    {
      address: "0x6b175474e89094c44da98b954eedeac495271d0f",
      symbol: "DAI",
    },
  ],
  [chains.goerli.chainId]: [
    {
      address: "0x97cb342cf2f6ecf48c1285fb8668f5a4237bf862",
      symbol: "DAI",
    },
  ],
  [chains.polygon.chainId]: [
    {
      address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
      symbol: "DAI",
    },
  ],
};

const tokens: Record<number, { address: string; symbol: string }[]> = {
  [chains.mainnet.chainId]: [
    {
      address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      symbol: "USDC",
    },
    {
      address: "0x6b175474e89094c44da98b954eedeac495271d0f",
      symbol: "DAI",
    },
    {
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      symbol: "wETH",
    },
  ],
  [chains.goerli.chainId]: [
    {
      address: "0x97cb342cf2f6ecf48c1285fb8668f5a4237bf862",
      symbol: "DAI",
    },
  ],
  [chains.polygon.chainId]: [
    {
      address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
      symbol: "WMATIC",
    },
    {
      address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
      symbol: "DAI",
    },
    {
      address: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      symbol: "USDC",
    },
  ],
};
const whitelist = {
  tokens,
  flash,
};

export default whitelist;
