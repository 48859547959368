import type { Locale } from "@sablier/v2-locales";
import type { IAutofillParamsURL } from "@sablier/v2-types";

function builder(
  base: string,
  params: { [key: string]: string | string[] | undefined },
) {
  return base.concat("?").concat(
    Object.keys(params)
      .map((item) => {
        if (String(params[item]) === params[item]) {
          return `${item}=${params[item]}`;
        }
        if (Array.isArray(params[item])) {
          return `${item}=${(params[item] as string[]).join(",")}`;
        }
      })
      .filter((item) => !(item === undefined || item === null || item === ""))
      .join("&"),
  );
}

export const pages = {
  createAirstream: {
    locales: "pages.airstream" as Locale,
    route: "/create/airstream",
    builder: () => "/create/airstream",
    title: "Airstream",
    depth: 1,
  },
  createGroup: {
    locales: "pages.createGroup" as Locale,
    route: "/create/group",
    builder: (params: { shape: string } & IAutofillParamsURL) =>
      builder("/create/group", params),
    title: "Create Streams",
    depth: 1,
  },
  createSingle: {
    locales: "pages.createSingle" as Locale,
    route: "/create/single",
    builder: (params: { shape: string } & IAutofillParamsURL) =>
      builder("/create/single", params),
    title: "Create Stream",
    depth: 1,
  },
  dashboard: {
    locales: "pages.dashboard" as Locale,
    route: "/",
    builder: () => "/",
    title: "Dashboard",
    depth: 0,
  },
  gallery: {
    locales: "pages.gallery" as Locale,
    route: "/gallery",
    builder: () => "/gallery",
    title: "Gallery",
    depth: 0,
  },
  galleryGroup: {
    locales: "pages.galleryGroup" as Locale,
    route: "/gallery/group",
    builder: () => "/gallery/group",
    title: "Gallery (Group)",
    depth: 1,
  },
  profile: {
    locales: "pages.profile" as Locale,
    route: "/stream/:id",
    builder: (id: string | undefined) => `/stream/${id}`,
    title: "Stream",
    depth: 1,
  },
};

export const api = {
  meta: {
    static: {
      route: "/api/meta/static",
      builder: (params: Record<string, string>) =>
        builder("/api/meta/static", params),
    },
    query: {
      route: "/api/meta/builder",
      builder: (id: string) => builder("/api/meta/query", { id }),
    },
  },
  stream: {
    route: "/api/stream",
    builder: (id: string) => builder("/api/stream", { id }),
  },
};

export const tabs = {
  dashboard: {
    all: {
      identifier: "all",
      route: pages.dashboard.route,
      builder: () => pages.dashboard.builder(),
      title: "All",
      titleShort: "All",
      params: { identifier: "t" },
    },
    recipient: {
      identifier: "recipient",
      route: pages.dashboard.route,
      builder: () => builder(pages.dashboard.builder(), { t: "recipient" }),
      title: "As recipient",
      titleShort: "In",
      params: { identifier: "t" },
    },
    search: {
      identifier: "search",
      route: pages.dashboard.route,
      builder: (params: {
        c: string;
        i?: string[];
        p?: string;
        s?: string;
        r?: string;
      }) => builder(pages.dashboard.builder(), { t: "search", ...params }),
      title: "Search",
      titleShort: "Search",
      params: {
        identifier: "t",
        chainId: "c",
        ids: "i",
        party: "p",
        /** -- Extra -- */
        sender: "s",
        recipient: "r",
      },
    },
    sender: {
      identifier: "sender",
      route: pages.dashboard.route,
      builder: () => builder(pages.dashboard.builder(), { t: "sender" }),
      title: "As sender",
      titleShort: "Out",
      params: { identifier: "t" },
    },
  },
};
